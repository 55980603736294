import { WalletAdapterNetwork } from "@solana/wallet-adapter-base"

export const getClusterUrl = (address, network) => {
  switch (network) {
    case "Localhost":
      return `https://explorer.solana.com/address/${address}?cluster=custom&customUrl=http%3A%2F%2Flocalhost%3A8899`
    case WalletAdapterNetwork.Testnet:
      return `https://explorer.solana.com/address/${address}?cluster=testnet`
    case WalletAdapterNetwork.Devnet:
      return `https://explorer.solana.com/address/${address}?cluster=devnet`
    default:
      return `https://explorer.solana.com/address/${address}`
  }
}