import { WalletModalContext } from './useWalletModal.js';
import { WalletModal } from './WalletModal.js';
import {useState} from "react";

export const WalletModalProvider = ({ children, ...props }) => {
    const [visible, setVisible] = useState(false);

    return (
        <WalletModalContext.Provider
            value={{
                visible,
                setVisible,
            }}
        >
            {children}
            {visible && <WalletModal {...props} />}
        </WalletModalContext.Provider>
    );
};
