import React from 'react';
import { useTheme } from '@emotion/react';
import { Button } from '@mui/material';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const ButtonUI = (props) => {
    const theme = useTheme();
    return (
        props.isConnect ?
            <Button
                onClick={props.onClick}
                className={`wallet-adapter-button ${props.className || ''}`}
                variant="contained"
                style={props.style}
                type="button"
                disabled={props.disabled}
                endIcon={props.endIcon && <i className="wallet-adapter-button-end-icon">{props.endIcon}</i>}
                startIcon={props.startIcon ? <i className="wallet-adapter-button-start-icon">{props.startIcon}</i> : <AccountBalanceWalletIcon />}
                sx={{ my: 1, background: `${theme.palette.background.light}!important`, borderRadius: '30px', color: '#efefef' }}>
                {/* Connect Wallet */}
                {props.children}
            </Button> : <button
                className={`wallet-adapter-button ${props.className || ''}`}
                disabled={props.disabled}
                style={props.style}
                onClick={props.onClick}
                tabIndex={props.tabIndex || 0}
                type="button"
            >
                {props.startIcon && <i className="wallet-adapter-button-start-icon">{props.startIcon}</i>}
                {props.children}
                {props.endIcon && <i className="wallet-adapter-button-end-icon">{props.endIcon}</i>}
            </button>
    );
};

export default ButtonUI
