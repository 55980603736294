import { TxCommon } from "utils/tx/txCommon";
import {
  PACKET_DATA_SIZE,
  TransactionInstruction,
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  SystemProgram,
  Transaction,
  Signer,
  ComputeBudgetProgram,
  sendAndConfirmRawTransaction,
  sendAndConfirmTransaction,

} from "@solana/web3.js";
import PgTx from "utils/tx/tx";
import * as BufferLayout from "@solana/buffer-layout";
import { encodeData } from "utils/instruction";
import { rustVecBytes } from "./Layout";
import { Buffer } from "buffer"
import { PgWallet } from "utils/wallet/wallet";

const BPF_LOADER_UPGRADEABLE_PROGRAM_ID = new PublicKey(
  "BPFLoaderUpgradeab1e11111111111111111111111"
);


const BPF_UPGRADEABLE_LOADER_INSTRUCTION_LAYOUTS = Object.freeze({
  InitializeBuffer: {
    index: 0,
    layout: BufferLayout.struct([
      BufferLayout.u32("instruction"),
    ]),
  },
  Write: {
    index: 1,
    layout: BufferLayout.struct([
      BufferLayout.u32("instruction"),
      BufferLayout.u32("offset"),
      rustVecBytes("bytes"),
    ]),
  },
  DeployWithMaxDataLen: {
    index: 2,
    layout: BufferLayout.struct([
      BufferLayout.u32("instruction"),
      BufferLayout.u32("maxDataLen"),
      BufferLayout.u32("maxDataLenPadding"),
    ]),
  },
  Upgrade: {
    index: 3,
    layout: BufferLayout.struct([
      BufferLayout.u32("instruction"),
    ]),
  },
  SetAuthority: {
    index: 4,
    layout: BufferLayout.struct([
      BufferLayout.u32("instruction"),
    ]),
  },
  Close: {
    index: 5,
    layout: BufferLayout.struct([
      BufferLayout.u32("instruction"),
    ]),
  },
});

export class BpfLoaderUpgradeableProgram {
  /** Public key that identifies the BpfLoaderUpgradeable program */
  static programId = BPF_LOADER_UPGRADEABLE_PROGRAM_ID

  /** Derive programData address from program. */
  static async getProgramDataAddress(programPk) {
    return (
      await PublicKey.findProgramAddress([programPk.toBuffer()], this.programId)
    )[0]
  }

  /** Generate a tx instruction that initialize buffer account. */
  static initializeBuffer(params) {
    const type = BPF_UPGRADEABLE_LOADER_INSTRUCTION_LAYOUTS.InitializeBuffer
    const data = encodeData(type, {})

    return new TransactionInstruction({
      keys: [
        { pubkey: params.bufferPk, isSigner: false, isWritable: true },
        { pubkey: params.authorityPk, isSigner: false, isWritable: false }
      ],
      programId: this.programId,
      data
    })
  }

  /**
   * Generate a tx instruction that write a chunk of program data to a buffer
   * account.
   */
  static write(params) {
    const type = BPF_UPGRADEABLE_LOADER_INSTRUCTION_LAYOUTS.Write
    const data = encodeData(type, {
      offset: params.offset,
      bytes: params.bytes
    })

    return new TransactionInstruction({
      keys: [
        { pubkey: params.bufferPk, isSigner: false, isWritable: true },
        { pubkey: params.authorityPk, isSigner: true, isWritable: false }
      ],
      programId: this.programId,
      data
    })
  }

  /**
   * Generate a tx instruction that deploy a program with a specified maximum
   * program length.
   */
  static async deployWithMaxProgramLen(params) {
    const type = BPF_UPGRADEABLE_LOADER_INSTRUCTION_LAYOUTS.DeployWithMaxDataLen
    const data = encodeData(type, {
      maxDataLen: params.maxDataLen,
      maxDataLenPadding: 0
    })

    const programDataPk = await this.getProgramDataAddress(params.programPk)
    return new TransactionInstruction({
      keys: [
        { pubkey: params.payerPk, isSigner: true, isWritable: true },
        { pubkey: programDataPk, isSigner: false, isWritable: true },
        { pubkey: params.programPk, isSigner: false, isWritable: true },
        { pubkey: params.bufferPk, isSigner: false, isWritable: true },
        { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false },
        { pubkey: SYSVAR_CLOCK_PUBKEY, isSigner: false, isWritable: false },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
        {
          pubkey: params.upgradeAuthorityPk,
          isSigner: true,
          isWritable: false
        }
      ],
      programId: this.programId,
      data
    })
  }

  /** Generate a tx instruction that upgrade a program. */
  static async upgrade(params) {
    const type = BPF_UPGRADEABLE_LOADER_INSTRUCTION_LAYOUTS.Upgrade
    const data = encodeData(type, {})

    const programDataPk = await this.getProgramDataAddress(params.programPk)

    return new TransactionInstruction({
      keys: [
        { pubkey: programDataPk, isSigner: false, isWritable: true },
        { pubkey: params.programPk, isSigner: false, isWritable: true },
        { pubkey: params.bufferPk, isSigner: false, isWritable: true },
        { pubkey: params.spillPk, isSigner: true, isWritable: true },
        { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false },
        { pubkey: SYSVAR_CLOCK_PUBKEY, isSigner: false, isWritable: false },
        {
          pubkey: params.authorityPk,
          isSigner: true,
          isWritable: false
        }
      ],
      programId: this.programId,
      data
    })
  }

  /** Generate a tx instruction that set a new buffer authority. */
  static setBufferAuthority(params) {
    const type = BPF_UPGRADEABLE_LOADER_INSTRUCTION_LAYOUTS.SetAuthority
    const data = encodeData(type, {})

    return new TransactionInstruction({
      keys: [
        { pubkey: params.bufferPk, isSigner: false, isWritable: true },
        {
          pubkey: params.authorityPk,
          isSigner: true,
          isWritable: false
        },
        {
          pubkey: params.newAuthorityPk,
          isSigner: false,
          isWritable: false
        }
      ],
      programId: this.programId,
      data
    })
  }

  /** Generate a tx instruction that set a new program authority. */
  static async setUpgradeAuthority(params) {
    const type = BPF_UPGRADEABLE_LOADER_INSTRUCTION_LAYOUTS.SetAuthority
    const data = encodeData(type, {})

    const programDataPk = await this.getProgramDataAddress(params.programPk)

    const keys = [
      { pubkey: programDataPk, isSigner: false, isWritable: true },
      {
        pubkey: params.authorityPk,
        isSigner: true,
        isWritable: false
      }
    ]

    if (params.newAuthorityPk) {
      keys.push({
        pubkey: params.newAuthorityPk,
        isSigner: false,
        isWritable: false
      })
    }

    return new TransactionInstruction({
      keys,
      programId: this.programId,
      data
    })
  }

  /**
   * Generate a tx instruction that close a program, a buffer, or an
   * uninitialized account.
   */
  static close(params) {
    const type = BPF_UPGRADEABLE_LOADER_INSTRUCTION_LAYOUTS.Close
    const data = encodeData(type, {})

    const keys = [
      { pubkey: params.closePk, isSigner: false, isWritable: true },
      {
        pubkey: params.recipientPk,
        isSigner: false,
        isWritable: true
      }
    ]

    if (params.authorityPk) {
      keys.push({
        pubkey: params.authorityPk,
        isSigner: true,
        isWritable: false
      })
    }

    if (params.programPk) {
      keys.push({
        pubkey: params.programPk,
        isSigner: false,
        isWritable: true
      })
    }

    return new TransactionInstruction({
      keys,
      programId: this.programId,
      data
    })
  }
}

export class BpfLoaderUpgradeable {
  /** Buffer account size without data */
  static BUFFER_HEADER_SIZE = 37 // Option<Pk>

  /** Program account size */
  static BUFFER_PROGRAM_SIZE = 36 // Pk

  /** ProgramData account size without data */
  static BUFFER_PROGRAM_DATA_HEADER_SIZE = 45 // usize + Option<Pk>

  /** Maximal chunk of the data per tx */
  static WRITE_CHUNK_SIZE =
    PACKET_DATA_SIZE - // Maximum transaction size
    720 - // Data with 1 signature
    44 // Priority fee instruction size

  /** Get buffer account size. */
  static getBufferAccountSize(programLen) {
    return this.BUFFER_HEADER_SIZE + programLen
  }

  /** Create and initialize a buffer account. */
  static async createBuffer(buffer, lamports, programLen, opts) {
    const { wallet, connection } = this._getOptions(opts)

    const tx = new Transaction()
    tx.add(
      SystemProgram.createAccount({
        fromPubkey: wallet.publicKey,
        newAccountPubkey: buffer.publicKey,
        lamports,
        space: this.getBufferAccountSize(programLen),
        programId: BPF_LOADER_UPGRADEABLE_PROGRAM_ID
      })
    )
    tx.add(
      BpfLoaderUpgradeableProgram.initializeBuffer({
        bufferPk: buffer.publicKey,
        authorityPk: wallet.publicKey
      })
    )

    return await PgTx.send(tx, {
      keypairSigners: [buffer],
      wallet, connection
    })
  }

  /** Update the buffer authority. */
  static async setBufferAuthority(bufferPk, newAuthorityPk, opts) {
    const { wallet, connection } = this._getOptions(opts)

    const tx = new Transaction()
    tx.add(
      BpfLoaderUpgradeableProgram.setBufferAuthority({
        bufferPk,
        authorityPk: wallet.publicKey,
        newAuthorityPk
      })
    )
    return await PgTx.send(tx, { wallet, connection })
  }
  static async send(
    tx,
    opts
  ) {
    const wallet = opts?.wallet;
    if (!wallet) throw new Error("Wallet not connected");
    const connection = opts?.connection;
    // Set priority fees if the transaction doesn't already have it
    const existingsetComputeUnitPriceIx = tx.instructions.find(
      (ix) =>
        ix.programId.equals(ComputeBudgetProgram.programId) &&
        ix.data.at(0) === 3 // setComputeUnitPrice
    );
    if (!existingsetComputeUnitPriceIx) {
      const priorityFeeInfo = await PgTx._getPriorityFee(connection);
      const priorityFeeSetting = "average" | "median" | "min" | "max";
      const priorityFee =
        typeof priorityFeeSetting === "number"
          ? priorityFeeSetting
          : priorityFeeInfo[priorityFeeSetting];
      if (priorityFee) {
        const setComputeUnitPriceIx =
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: priorityFee,
          });
        tx.instructions = [setComputeUnitPriceIx, ...tx.instructions];
      }
    }

    tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
    tx.feePayer = wallet.publicKey;

    let txHash;
    try {
      txHash = await connection.sendRawTransaction(tx.serialize({ requireAllSignatures: false }), {
        skipPreflight: true,
      });
    } catch (e) {
      if (
        e.message.includes("This transaction has already been processed") ||
        e.message.includes("Blockhash not found")
      ) {
        // Reset signatures
        tx.signatures = [];
        return await this.send(tx, {
          ...opts,
          forceFetchLatestBlockhash: true,
        });
      }

      throw e;
    }

    return txHash;
  }
  // static async sendTransaction(transaction, { connection, wallet }) {
  //   // const existingsetComputeUnitPriceIx = transaction.instructions.find(
  //   //   (ix) =>
  //   //     ix.programId.equals(ComputeBudgetProgram.programId) &&
  //   //     ix.data.at(0) === 3 // setComputeUnitPrice
  //   // );
  //   // if (!existingsetComputeUnitPriceIx) {
  //   //   const priorityFeeInfo = await this._getPriorityFee(connection);
  //   //   const priorityFeeSetting = "average" | "median" | "min" | "max";
  //   //   const priorityFee =
  //   //     typeof priorityFeeSetting === "number"
  //   //       ? priorityFeeSetting
  //   //       : priorityFeeInfo[priorityFeeSetting];
  //   //   if (priorityFee) {
  //   //     const setComputeUnitPriceIx =
  //   //       ComputeBudgetProgram.setComputeUnitPrice({
  //   //         microLamports: priorityFee,
  //   //       });
  //   //     transaction.instructions = [setComputeUnitPriceIx, ...transaction.instructions];
  //   //   }
  //   // }
  //   transaction.recentBlockhash = await connection.getRecentBlockhash();
  //   transaction.feePayer = wallet.publicKey;
  //   const signedTransaction = await wallet.signTransaction(transaction);
  //   const signature = await connection.sendRawTransaction(signedTransaction.serialize());
  //   await connection.confirmTransaction(signature);
  //   return signature;
  // }


  /** Load programData to the initialized buffer account. */
  static async loadBuffer(bufferPk, programData, opts) {
    const { connection, wallet } = this._getOptions(opts)
    const { loadConcurrency } = TxCommon.setDefault(opts, {
      loadConcurrency: 8
    })
    const loadBuffer = async (indices, isMissing) => {
      if (isMissing) opts?.onMissing?.(indices.length)

      let i = 0
      await Promise.all(
        new Array(loadConcurrency).fill(null).map(async () => {
          while (1) {
            // if (opts?.abortController?.signal.aborted) return

            const offset = indices[i] * BpfLoaderUpgradeable.WRITE_CHUNK_SIZE
            i++
            const endOffset = offset + BpfLoaderUpgradeable.WRITE_CHUNK_SIZE
            const bytes = programData.slice(offset, endOffset)
            if (bytes.length === 0) break
            const tx = new Transaction()
            tx.add(
              BpfLoaderUpgradeableProgram.write({
                offset,
                bytes,
                bufferPk,
                authorityPk: wallet.publicKey
              })
            )
            try {
              await PgTx.send(tx, { connection, wallet });
              if (!isMissing) opts?.onWrite?.(endOffset);
            } catch (e) {
              console.log("Buffer write error:", e.message);
              console.log("Failed transaction at offset:", offset);
              console.log("Failed transaction at endOffset:", endOffset);
            }
          }
        })
      )
    }

    const txCount = Math.ceil(
      programData.length / BpfLoaderUpgradeable.WRITE_CHUNK_SIZE
    )
    const indices = new Array(txCount).fill(null).map((_, i) => i)
    let isMissing = false

    // Retry until all bytes are written
    while (1) {
      // if (opts?.abortController?.signal.aborted) return

      // Wait for last transaction to confirm
      await TxCommon.sleep(500)

      // Even though we only get to this function after buffer account creation
      // gets confirmed, the RPC can still return `null` here if it's behind.
      const bufferAccount = await TxCommon.tryUntilSuccess(async () => {
        const acc = await connection.getAccountInfo(bufferPk)
        if (!acc) throw new Error()
        return acc
      }, 2000)

      const onChainProgramData = bufferAccount.data.slice(
        BpfLoaderUpgradeable.BUFFER_HEADER_SIZE,
        BpfLoaderUpgradeable.BUFFER_HEADER_SIZE + programData.length
      )
      if (onChainProgramData.equals(programData)) break

      const missingIndices = indices
        .map(i => {
          const start = i * BpfLoaderUpgradeable.WRITE_CHUNK_SIZE
          const end = start + BpfLoaderUpgradeable.WRITE_CHUNK_SIZE
          const actualSlice = programData.slice(start, end)
          const onChainSlice = onChainProgramData.slice(start, end)
          if (!actualSlice.equals(onChainSlice)) return i

          return null
        })
        .filter(i => i !== null)
      // const batchSize = Math.min(3, missingIndices.length);
      // for (let j = 0; j < missingIndices.length; j += batchSize) {
      //   await writeChunks(missingIndices.slice(j, j + batchSize));
      // }
      await loadBuffer(missingIndices, isMissing)
      isMissing = true;
    }
  }

  // static async loadBuffer(
  //   bufferPk, programData, opts
  // ) {
  //   let bytesOffset = 0;
  //   const { connection, wallet } = this._getOptions(opts)
  //   await Promise.all(
  //     new Array(8).fill(null).map(async () => {
  //       for (; ;) {
  //         const offset = bytesOffset;
  //         bytesOffset += BpfLoaderUpgradeable.WRITE_CHUNK_SIZE;

  //         const bytes = programData.slice(
  //           offset,
  //           offset + BpfLoaderUpgradeable.WRITE_CHUNK_SIZE
  //         );
  //         if (bytes.length === 0) {
  //           break;
  //         }

  //         const tx = new Transaction();
  //         tx.add(
  //           BpfLoaderUpgradeableProgram.write({
  //             offset,
  //             bytes,
  //             bufferPk,
  //             authorityPk: wallet.publicKey,
  //           })
  //         );

  //         let sleepAmount = 1000;
  //         // Retry until writing is successful
  //         for (; ;) {
  //           try {
  //             const writeTxHash = await PgTx.send(tx, { connection, wallet, isSign: true });

  //             console.count("buffer write");

  //             // Don't confirm on localhost
  //             if (!connection.rpcEndpoint.includes("localhost")) {
  //               const txResult = await PgTx.confirm(writeTxHash, connection);
  //               if (!txResult?.err) break;
  //             } else break;
  //           } catch (e) {
  //             console.log("Buffer write error:", e.message);

  //             await TxCommon.sleep(sleepAmount);
  //             // Incrementally sleep incase of being rate-limited
  //             if (sleepAmount < 60000) sleepAmount *= 1.5;
  //           }
  //         }
  //       }
  //     })
  //   );

  //   console.countReset("buffer write");
  // }
  /** Close the buffer account and withdraw funds. */
  static async closeBuffer(bufferPk, opts) {
    const { wallet, connection } = this._getOptions(opts);

    const tx = new Transaction()
    tx.add(
      BpfLoaderUpgradeableProgram.close({
        closePk: bufferPk,
        recipientPk: wallet.publicKey,
        authorityPk: wallet.publicKey,
        programPk: undefined
      })
    )

    return await PgTx.send(tx, { wallet, connection })
  }

  /** Create a program account from initialized buffer. */
  static async deployProgram(
    program,
    bufferPk,
    programLamports,
    maxDataLen,
    opts
  ) {
    const { wallet, connection } = this._getOptions(opts)

    const tx = new Transaction()
    tx.add(
      SystemProgram.createAccount({
        fromPubkey: wallet.publicKey,
        newAccountPubkey: program.publicKey,
        lamports: programLamports,
        space: this.BUFFER_PROGRAM_SIZE,
        programId: BPF_LOADER_UPGRADEABLE_PROGRAM_ID
      })
    )
    tx.add(
      await BpfLoaderUpgradeableProgram.deployWithMaxProgramLen({
        programPk: program.publicKey,
        bufferPk,
        upgradeAuthorityPk: wallet.publicKey,
        payerPk: wallet.publicKey,
        maxDataLen
      })
    )

    return await PgTx.send(tx, {
      wallet,
      keypairSigners: [program],
      connection
    })
  }

  /** Update the program authority. */
  static async setProgramAuthority(programPk, newAuthorityPk, opts) {
    const { wallet } = this._getOptions(opts)

    const tx = new Transaction()
    tx.add(
      await BpfLoaderUpgradeableProgram.setUpgradeAuthority({
        programPk,
        authorityPk: wallet.publicKey,
        newAuthorityPk
      })
    )

    return await PgTx.send(tx, { wallet })
  }

  /** Upgrade a program. */
  static async upgradeProgram(programPk, bufferPk, opts) {
    const { wallet } = this._getOptions(opts)

    const tx = new Transaction()
    tx.add(
      await BpfLoaderUpgradeableProgram.upgrade({
        programPk,
        bufferPk,
        authorityPk: wallet.publicKey,
        spillPk: wallet.publicKey
      })
    )

    return await PgTx.send(tx, { wallet })
  }

  /** Close the program account and withdraw funds. */
  static async closeProgram(programPk, opts) {
    const { wallet } = this._getOptions(opts)

    const tx = new Transaction()
    tx.add(
      BpfLoaderUpgradeableProgram.close({
        closePk: await BpfLoaderUpgradeableProgram.getProgramDataAddress(
          programPk
        ),
        recipientPk: wallet.publicKey,
        authorityPk: wallet.publicKey,
        programPk
      })
    )

    return await PgTx.send(tx, { wallet })
  }

  /** Get the connection and wallet instance. */
  static _getOptions(opts) {
    const connection = opts?.connection;
    const wallet = opts?.wallet ?? PgWallet.current
    if (!wallet) throw new Error("Wallet is not connected")

    return { connection, wallet }
  }
}
