import { Grid, Box, styled, Avatar, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomCard = styled('div')(({ isChain, theme }) => ({
  maxHeight: '90px',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 1rem',
  borderRadius: '7px',
  backgroundColor: theme.palette.primary.white,
  cursor: 'pointer',
  boxShadow: isChain && '0px 0px 12px #70C492 ',
}));

const Circle = styled('div')({
  borderRadius: '50%',
  backgroundColor: '#F3BA2F33',
  height: '90%',
});

const disableStyle = {
  backgroundColor: 'rgba(255, 255, 255, 0.12)',
  pointerEvents: 'none',
}

const BlockchainTile = ({ chain, isActive, login = false, changeNetwork }) => {

  const handleChangeNetwork = (chain) => {
    changeNetwork(chain)
  }

  return (
    <Box sx={{ minWidth: 100, minHeight: 70 }}>
      <Tooltip title={chain?.isDisable ? 'This node is being created. Please go to nodes page to see the current status.' : ''} arrow>
        <div>
          <CustomCard variant="outlined" isChain={isActive === chain.id} onClick={() => handleChangeNetwork(chain)} sx={chain?.isDisable ? { ...disableStyle } : {}}>
            <div>
              <Grid container>
                {/* Chain Symbol */}
                <Grid item xs={3}>
                  {chain?.image ? <Avatar variant='square' src={chain.image} sx={{ width: 60, height: 50 }} /> : <Circle />}
                </Grid>
                {/* Chain Name */}
                <Grid item xs={9} sx={{ padding: '0.5rem 0.5rem 0.5rem 1.5rem' }}>
                  {/* <CardContent> */}
                  <Tooltip title={chain.name} arrow>
                    <Typography variant={'h3'} sx={{ color: '#121337', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                      {chain.name}
                    </Typography>
                  </Tooltip>
                  <Typography sx={{ color: '#121337' }} variant="body2">
                    {chain.network}
                  </Typography>
                  {/* </CardContent> */}
                </Grid>
                {/* Active Icon */}
                <Grid item xs={1}>
                  {!login && <CheckCircleIcon color={isActive === chain.id ? 'success' : chain?.isDisable ? 'disabled' : '70C492'} />}
                </Grid>
              </Grid>
            </div>
          </CustomCard>
        </div>
      </Tooltip>
    </Box>
  );
};

export default BlockchainTile;
