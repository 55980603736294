import { Typography, styled, Box } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const WalletBox = styled('Box')(({ theme, balance }) => ({
  borderRadius: '1rem',

  boxShadow: balance == 0 ? '2px 2px 12px red' : '2px 2px 12px green',

  background: theme.palette.background.default,
  padding: '1rem',
  height: '90%',
  margin: '0.5rem 0',
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

const WalletBalance = ({ balance, symbol, signed }) => {
  return (
    <Box id="available-balance" sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography gutterBottom variant="h3">
        Available Balance:
      </Typography>
      <Typography gutterBottom variant="body2" sx={{ opacity: '0.6' }}>
        *Make sure your wallet has enough Gas fees to deploy the contract to the blockchain of your choice
      </Typography>
      <WalletBox balance={balance || !signed}>
        <AccountBalanceWalletIcon sx={{ marginRight: '0.5rem' }} />
        {balance} {symbol}

        {/*  */}
      </WalletBox>
    </Box>
  );
};

export default WalletBalance;
