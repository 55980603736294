import { useMemo } from "react";
import router from "config/routes";
import { theme } from "config/theme";
import { ToastContainer } from "react-toastify";
import { RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { store } from "store";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import "components/wallet/style.css";
import WalletContextProvider from "../../providers/WalletContextProvider";
// initialize the module
import * as buffer from "buffer";
import { SolanaState } from "providers/SolanaState";

function App() {
  let themeConfig = createTheme(theme);

  themeConfig = responsiveFontSizes(themeConfig);
  window.Buffer = buffer.Buffer;

  return (
    <Provider store={store}>

      <ThemeProvider theme={themeConfig}>
        <WalletContextProvider>
          <ToastContainer style={{ top: 74 }} />
          <CssBaseline />
          <SolanaState />
          <RouterProvider router={router} />
        </WalletContextProvider>

      </ThemeProvider>
    </Provider>
  );
}

export default App;
