import React from 'react';
import { WalletIcon } from './WalletIcon.js';
import Button from "./Button.js"

export function BaseWalletConnectionButton({ walletIcon, walletName, ...props }) {
    return (
        <Button
            {...props}
            className="wallet-adapter-button-trigger"
            startIcon={
                walletIcon && walletName ? (
                    <WalletIcon wallet={{ adapter: { icon: walletIcon, name: walletName } }} />
                ) : undefined
            }
        />
    );
}
