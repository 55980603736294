import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getContract } from 'store/services/contracts';
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  Button,
  Grid,
  Container,
  Box,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import BlockchainTile from "./blockChainTile";
import DeployIcon from "assets/icons/deploy.svg";
import { supportedChains } from "config/networkChain";
import { ContractTitle, StepperDetails } from "pages/smartContract";
import { updateContract } from "store/services/contracts";
import { setContract, setSelectedContract } from "store/reducers/contract";
import Loader from "common/Loader";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { setSelectedNetworkUrl } from "store/reducers/wallet";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import WalletBalance from "components/wallet/WalletBalance";
import {
  Transaction,
  SystemProgram,
  Keypair,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import PgTx from "utils/tx/tx";
import { BpfLoaderUpgradeable } from "utils/buffer/bpf-upgradeable-browser";
import { Buffer } from "buffer"
import { PgWallet } from "utils/wallet/wallet";
import { WalletMultiButton } from "components/wallet";
import { PgCommon } from "utils/common";

const BalanceCard = styled(Box)({
  padding: "1rem 1.5rem",
  borderRadius: "1rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledContainer = styled(Container)({
  height: "inherit",
  paddingRight: 0,
});

const Deployment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { connection } = useConnection()
  const wallet = useWallet();
  const publicKey = wallet?.publicKey;

  const [balance, setBalance] = useState(null);
  const { contractState } = useSelector((state) => state?.contract);
  const [loading, setLoading] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(supportedChains[1]);
  const location = useLocation();

  const fetchBalance = async () => {
    if (connection && publicKey) {
      const balance1 = await connection.getBalance(publicKey);
      setBalance(balance1 / LAMPORTS_PER_SOL);
    }
  };

  const getContractDetails = async (cID) => {
    try {
      setLoading(true)
      const contract = await getContract(cID);
      dispatch(setContract(contract));

      // set main contract as default selected file
      dispatch(setSelectedContract(contract));

    }
    catch (err) {
      console.log(err)
      toast.error(err?.message, {
        style: { top: '3.5em' },
      });
    }
    finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    const cID = location.pathname?.split('/')?.pop();
    if (cID && !contractState?._id) {
      getContractDetails(cID);
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchBalance();
  }, [connection, publicKey]);

  //   const children =
  //     wallet?.accounts?.map((account, ind) => (
  //       <MenuItem key={ind} value={account?.address}>
  //         {truncateAddress(account.address)}
  //       </MenuItem>
  //     )) || [];
  //   if (wallet?.accounts?.length)
  //     children.push(
  //       <MenuItem key={wallet?.accounts?.length} value={"disconnectwallet"}>
  //         {"Disconnect Wallet"}
  //       </MenuItem>
  //     );

  //   useEffect(() => {
  //     if (!wallet) return;
  //     const _selectedAccount = selectedAccount
  //       ? selectedAccount
  //       : wallet?.accounts[0]?.address;
  //     dispatch(setSelectedAccount(_selectedAccount));
  //   }, [wallet]);

  const handleAccountSelection = (event) => {
    // if (event.target.value === "disconnectwallet") {
    //   disconnect(wallet);
    //   dispatch(setSelectedAccount(""));
    //   return;
    // }
    // dispatch(setSelectedAccount(event.target.value));
  };

  const sleep = (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  }


  const sendAndConfirmTxWithRetries = async (
    sendTx,
    checkConfirmation
  ) => {
    let sleepAmount = 1000;
    let errMsg;
    for (let i = 0; i < 5; i++) {
      try {
        const txHash = await sendTx();
        const result = await PgTx.confirm(txHash, connection);
        if (!result?.err) return txHash;
        if (await checkConfirmation()) return txHash;
      } catch (e) {
        errMsg = e.message;
        console.log(errMsg);
        await sleep(sleepAmount);
        sleepAmount *= 1.8;
      }
    }

    throw new Error(
      `Exceeded maximum amount of retries 5).
  This might be an RPC related issue. Consider changing the endpoint from the settings.
  Reason: ${errMsg}`
    );
  };

  const handleAgreeDeploy = async () => {
    try {
      if (!publicKey) {
        toast.error("Please connect wallet to deploy contract", {
          style: { top: "3.5em" },
        });
      }
      else {
        setLoading(true);
        const _clonedContract = _.cloneDeep(contractState);
        const programKp = Keypair.generate();
        const programPk = programKp.publicKey;
        const programBinary = Buffer.from(contractState.programData, "base64");
        const programLen = programBinary.length;
        const bufferKp = Keypair.generate();
        const [pgWallet, standardWallet] = [PgWallet.createWallet(PgWallet.accounts[0]), wallet];
        const bufferSize = BpfLoaderUpgradeable.getBufferAccountSize(programLen); // Adjust as needed for size
        const bufferBalance = await connection.getMinimumBalanceForRentExemption(bufferSize);
        const [programExists, userBalance] = await Promise.all([
          connection.getAccountInfo(programPk),
          connection.getBalance(publicKey),
        ]);
        const requiredBalanceWithoutFees = programExists
          ? bufferBalance
          : 3 * bufferBalance;

        if (userBalance < requiredBalanceWithoutFees) {
          const msg = programExists
            ? `Initial deployment costs ${PgCommon.lamportsToSol(requiredBalanceWithoutFees).toFixed(2)
            } SOL but you have ${PgCommon.lamportsToSol(userBalance).toFixed(2)
            } SOL.`
            : `Upgrading costs ${PgCommon.lamportsToSol(bufferBalance).toFixed(2)
            } SOL but you have ${PgCommon.lamportsToSol(userBalance).toFixed(2)
            } SOL. ${PgCommon.lamportsToSol(bufferBalance).toFixed(2)
            }`;
          toast.error(msg);
          return
        }

        if (standardWallet) {
          // Transfer extra 0.1 SOL for fees (doesn't have to get used)
          const requiredBalance =
            requiredBalanceWithoutFees + LAMPORTS_PER_SOL / 10;
          const transferIx = SystemProgram.transfer({
            fromPubkey: standardWallet.publicKey,
            toPubkey: pgWallet.publicKey,
            lamports: requiredBalance,
          });
          const transferTx = new Transaction().add(transferIx);
          await sendAndConfirmTxWithRetries(
            () => PgTx.send(transferTx, { connection }),
            async () => {
              const currentBalance = await connection.getBalance(
                standardWallet.publicKey
              );
              return currentBalance < userBalance - requiredBalance;
            }
          );
        }

        await sendAndConfirmTxWithRetries(
          async () => {
            return await BpfLoaderUpgradeable.createBuffer(
              bufferKp,
              bufferBalance,
              programLen,
              { wallet: pgWallet, connection }
            );
          },
          async () => {
            const bufferAcc = await connection.getAccountInfo(bufferKp.publicKey);
            return !!bufferAcc;
          }
        );

        const closeBuffer = async () => {
          await BpfLoaderUpgradeable.closeBuffer(bufferKp.publicKey, { connection });
        };

        await BpfLoaderUpgradeable.loadBuffer(bufferKp.publicKey, programBinary, {
          wallet: pgWallet, connection
        });

        if (standardWallet) {
          await sendAndConfirmTxWithRetries(
            async () => {
              return await BpfLoaderUpgradeable.setBufferAuthority(
                bufferKp.publicKey,
                standardWallet.publicKey,
                { wallet: pgWallet, connection }
              );
            },
            async () => {
              const bufferAcc = await connection.getAccountInfo(bufferKp.publicKey);
              console.log("standardWallet", standardWallet)
              const isBufferAuthority = bufferAcc?.data
                .slice(5, 37)
                .equals(standardWallet?.publicKey?.toBuffer());
              return !!isBufferAuthority;
            }
          );
        }
        let txHash;
        try {
          txHash = await sendAndConfirmTxWithRetries(
            async () => {
              if (!programExists) {
                // First deploy needs keypair
                // console.log("programKp", programKp)
                if (!programKp) {
                  // TODO: Break out of the retries
                  throw new Error(
                    "Initial deployment needs a keypair but you've only provided a public key."
                  );
                }

                // Check whether customPk and programPk matches
                if (!programKp.publicKey.equals(programPk)) {
                  // TODO: Break out of the retries
                  throw new Error(
                    [
                      "Entered program id doesn't match program id derived from program's keypair. Initial deployment can only be done from a keypair.",
                      "You can fix this in 3 different ways:",
                      `1. Remove the custom program id from `,
                      "2. Import the program keypair for the current program id",
                      "3. Create a new program keypair",
                    ].join("\n")
                  );
                }

                const programSize = BpfLoaderUpgradeable.getBufferAccountSize(
                  BpfLoaderUpgradeable.BUFFER_PROGRAM_SIZE
                );
                const programBalance =
                  await connection.getMinimumBalanceForRentExemption(programSize);

                return await BpfLoaderUpgradeable.deployProgram(
                  programKp,
                  bufferKp.publicKey,
                  programBalance,
                  programLen * 2,
                  { connection }
                );
              } else {
                // Upgrade
                return await BpfLoaderUpgradeable.upgradeProgram(
                  programKp,
                  bufferKp.publicKey,
                  { connection }
                );
              }
            },
            async () => {
              const bufferAcc = await connection.getAccountInfo(bufferKp.publicKey);
              return !bufferAcc;
            }
          );
        } catch (e) {
          await closeBuffer();
          throw e;
        }
        updateContract(_clonedContract?._id, {
          contractHash: txHash,
          contractAddress: programPk.toString(),
          chain: connection.rpcEndpoint,
        });
        _clonedContract.hash = txHash;
        _clonedContract.address = programPk.toString();
        _clonedContract.chain = connection.rpcEndpoint;
        dispatch(setContract(_clonedContract));
        navigate(`/smartcontracts/${_clonedContract?._id}`);
        toast.success("Contract deployed successfully.", {
          style: { top: "3.5em" },
        });
        return txHash;
      }
    }
    catch (error) {
      console.log("================>ERROR", error);
      toast.error(error.message, {
        style: { top: "3.5em" },
      });
    }
    finally {
      setLoading(false)
    }
  };

  const handleChangeNetwork = (data) => {
    setSelectedNetwork(data);
    dispatch(setSelectedNetworkUrl(data?.network));
  }

  return (
    <StyledContainer maxWidth="false">
      {loading && <Loader />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: "1rem 0 1rem 0.4rem",
          }}
        >
          <ContractTitle />
          <StepperDetails />
          <Box mt={"25px"}>
            <Typography variant="h2">Blockchain</Typography>
            <Typography>
              Select the network to deploy the smart contract
            </Typography>
          </Box>
        </Box>
        <Box>
          <WalletMultiButton />
          {/* {!wallet?.accounts?.length ? (
            <Button
              onClick={() => connect()}
              variant="contained"
              startIcon={<AccountBalanceWalletIcon />}
              sx={{
                my: 1,
                background: `${theme.palette.background.light}!important`,
                borderRadius: "30px",
                color: "#efefef",
              }}
            >
              Connect Wallet
            </Button>
          ) : (
            <Select
              label={"Connected Account"}
              value={selectedAccount}
              onChange={handleAccountSelection}
              children={children}
            />
          )} */}
        </Box>
      </Box>

      {/* Available Blockchains */}

      {/* <CustomScrollbar autoHeight autoHeightMax={'calc(100vh - 400px)'}> */}
      <Box sx={{ padding: "0rem 1rem 2rem 0.4rem" }} mt={"2rem"}>
        <Grid
          id="select-blockchain-network"
          container
          justifyContent="center"
          spacing={3}
        >
          {supportedChains.map((chain) => {
            if (process.env.NODE_ENV === "production" && chain.network === "Localhost") {
              return;
            }
            return (
              <Grid key={chain.chain_id} item xs={3}>
                <BlockchainTile
                  chain={chain}
                  isActive={selectedNetwork?.id}
                  changeNetwork={handleChangeNetwork}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {/* </CustomScrollbar> */}

      {/* Available Balance */}
      <BalanceCard>
        <WalletBalance network={selectedNetwork} balance={balance} signed={publicKey} />
        <Button
          id="deployment-complete"
          sx={{
            height: "45px",
            width: "120px",
            color: "white",
            backgroundColor: "#518574",
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
          variant="contained"
          startIcon={<img src={DeployIcon} alt="icon" />}
          disabled={balance <= 0}
          size="large"
          onClick={handleAgreeDeploy}
        >
          Deploy
        </Button>
      </BalanceCard>
    </StyledContainer>
  );
};

export default Deployment;
