import {
  ConnectionProvider,
  WalletProvider,
  useWallet
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom"
import { useMemo, useEffect } from "react";
import { WalletModalProvider } from "components/wallet";
import { useSelector } from "react-redux";
import { PgWallet } from "../utils/wallet/wallet";
const WalletContextProvider = ({ children }) => {
  const { selectedNetwork } = useSelector((state) => state.wallet)
  const endpoint = useMemo(() => clusterApiUrl(selectedNetwork), [selectedNetwork]);
  const wallets = [new PhantomWalletAdapter()]

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <PgWalletProvider>
          <WalletModalProvider>{children}</WalletModalProvider>
        </PgWalletProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

const PgWalletProvider = ({ children }) => {
  const { wallets, publicKey } = useWallet();

  useEffect(() => {
    const id = setTimeout(() => {
      PgWallet.standardWallets = wallets;
    });
    return () => clearTimeout(id);
  }, [wallets, publicKey]);

  return <>{children}</>;
};

export default WalletContextProvider;