import { createSlice } from '@reduxjs/toolkit'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'

const initialState = {
    selectedAccount: '',
    selectedNetwork: WalletAdapterNetwork.Testnet
}

export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setSelectedAccount: (state, { payload }) => {
            state.selectedAccount = payload
        },
        setSelectedNetworkUrl: (state, { payload }) => {
            state.selectedNetwork = payload
        },
    },
})

export const { setSelectedNetworkUrl, setSelectedAccount } = walletSlice.actions

export default walletSlice.reducer