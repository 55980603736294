import React from 'react';
import { Outlet, Navigate } from "react-router-dom";
import { Box } from '@mui/system';
import { styled } from '@mui/material';
import MiniDrawer from 'common/Drawer';
import { getUserState } from 'utils/httpRequest';
import { useDisposable } from 'hooks/useDisposable';
import { PgWallet } from 'utils/wallet/wallet';

const PageContainer = styled(Box)(({ theme }) => ({
  padding: 16,
  display: 'flex',
  height: '100vh',
  width: '100vw',
  overflow: 'hidden',
}));

function SmartContractLayout(props) {
  const { token } = getUserState();
  return (
    <div>
      <PageContainer>
        <MiniDrawer page={props.page} />
        {token ? <Outlet /> : <Navigate to='/signin' />}

      </PageContainer>
    </div>
  );
}

export default SmartContractLayout;
