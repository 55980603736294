export class TxCommon {
  static async sleep(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  static async timeout(promisable, ms) {
    if ((promisable)?.call) {
      promisable = (promisable)();
    }

    return (await Promise.race([
      new Promise((_, rej) => this.sleep(ms).then(() => rej("Timed out"))),
      promisable,
    ]));
  }

  static async tryUntilSuccess(
    cb,
    tryInterval
  ) {
    let returnValue;
    while (1) {
      const start = performance.now();
      try {
        returnValue = await this.timeout(cb, tryInterval);
        break;
      } catch {
        const remaining = tryInterval - (performance.now() - start);
        if (remaining > 0) await this.sleep(remaining);
      }
    }

    return returnValue;
  }
  /**
 * Get send and receive event names
 *
 * @param eventName name of the custom event
 * @returns names of the send and receive
 */
  static getSendAndReceiveEventNames(eventName) {
    const send = eventName + "send";
    const receive = eventName + "receive";
    return { send, receive };
  }
  /**
 * Dispatch a custom DOM event
 *
 * @param name custom event name
 * @param detail data to send with the custom event
 */
  static createAndDispatchCustomEvent(name, detail) {
    const customEvent = new CustomEvent(name, { detail });
    document.dispatchEvent(customEvent);
  }

  static async sendAndReceiveCustomEvent(
    eventName,
    data
  ) {
    console.log("this.getSendAndReceiveEventNames(eventName)", this.getSendAndReceiveEventNames(eventName))

    const eventNames = this.getSendAndReceiveEventNames(eventName);
    console.log("eventNames", eventNames)
    // Send data
    this.createAndDispatchCustomEvent(eventNames.send, data);
    console.log("this.createAndDispatchCustomEvent(eventNames.send, data)", this.createAndDispatchCustomEvent(eventNames.send, data))
    // Wait for data
    return new Promise((res, rej) => {
      const handleReceive = (
        ev
      ) => {
        document.removeEventListener(
          eventNames.receive,
          handleReceive
        );

        if (ev.detail.error) {
          rej({ message: ev.detail.error });
        } else {
          res(ev.detail.data);
        }
      };

      document.addEventListener(
        eventNames.receive,
        handleReceive
      );
    });
  }
  static getStaticEventNames(eventName) {
    const get = eventName + "get";
    const run = eventName + "run";
    return { get, run };
  }
  static makePlural(noun, length, plural) {
    if (length > 1) return plural ?? noun + "s";
    return noun;
  }

  /**
 * Debounce the given callback.
 *
 * @param cb callback to debounce
 * @param options -
 * - delay: how long to wait before running the callback
 * - sharedTimeout: shared timeout object
 */
  static debounce(
    cb,
    options
  ) {
    const delay = options?.delay ?? 100;
    const sharedTimeout = options?.sharedTimeout ?? {};

    return () => {
      sharedTimeout.id && clearTimeout(sharedTimeout.id);
      sharedTimeout.id = setTimeout(cb, delay);
    };
  }

  /**
 * Batch changes together.
 *
 * @param cb callback to run
 * @param onChanges onChange methods
 * @returns a dispose function to clear all events
 */
  static batchChanges(
    cb,
    onChanges,
    opts
  ) {
    // Intentionally initializing outside of the closure to share `sharedTimeout`
    const debounceOptions = { delay: opts?.delay ?? 0, sharedTimeout: {} };

    const disposables = onChanges.map((onChange) => {
      return onChange(TxCommon.debounce(cb, debounceOptions));
    });

    return {
      dispose: () => disposables.forEach((disposable) => disposable.dispose()),
    };
  }

  /**
   * Handle change event.
   *
   * If `params.initialRun` is specified, the callback will run immediately with
   * the given `params.initialRun.value`. Any subsequent runs are only possible
   * through the custom event listener.
   *
   * @returns a dispose function to clear the event
   */
  static onDidChange(params) {
    const handle = (ev) => {
      params.cb(ev.detail);
    };

    if (params.initialRun) handle({ detail: params.initialRun.value });

    document.addEventListener(params.eventName, handle);
    return {
      dispose: () => {
        document.removeEventListener(params.eventName, handle);
      },
    };
  }

  /**
 * Convert the given input to an array when the input is not an array.
 *
 * @param arrayable input to convert to array to
 * @returns the array result
 */
  static toArray(arrayable) {
    return Array.isArray(arrayable) ? arrayable : [arrayable];
  }

  /**
 * Access the property value from `.` seperated input.
 *
 * @param obj object to get property from
 * @param property `.` seperated property input
 */
  static getProperty(obj, property) {
    if (Array.isArray(property)) property = property.join(".");
    return property.split(".").reduce((acc, cur) => acc[cur], obj);
  }

  static setDefault(value, defaultValue) {
    value ??= {};
    for (const property in defaultValue) {
      const result = defaultValue[property];
      value[property] ??= result;
    }

    return value;
  }
}


