import { ComputeBudgetProgram } from "@solana/web3.js";
import { PgWallet } from "utils/wallet/wallet";

class PgTx {
  static _cachedPriorityFee = null;
  static _cachedBlockhashInfo = null;

  static async _getPriorityFee(conn) {
    // Check whether the priority fee info has expired
    const timestamp = Math.floor(Date.now() / 1000);

    // There is not a perfect way to estimate for how long the priority fee will
    // be valid since it's a guess about the future based on the past data
    if (
      !this._cachedPriorityFee ||
      timestamp > this._cachedPriorityFee.timestamp + 60
    ) {
      const result = await conn.getRecentPrioritizationFees();
      const fees = result.map((fee) => fee.prioritizationFee).sort();

      this._cachedPriorityFee = {
        min: Math.min(...fees),
        max: Math.max(...fees),
        average: Math.ceil(fees.reduce((acc, cur) => acc + cur) / fees.length),
        median: fees[Math.floor(fees.length / 2)],
        timestamp,
      };
    }

    return this._cachedPriorityFee;
  }
  static async _getLatestBlockhash(
    conn,
    force
  ) {
    // Check whether the latest saved blockhash is still valid
    const timestamp = Math.floor(Date.now() / 1000);
    // Blockhashes are valid for 150 slots, optimal block time is ~400ms
    // For finalized: (150 - 32) * 0.4 = 47.2s ~= 45s (to be safe)
    if (
      force ||
      !this._cachedBlockhashInfo ||
      timestamp > this._cachedBlockhashInfo.timestamp + 45
    ) {
      this._cachedBlockhashInfo = {
        blockhash: (await conn.getLatestBlockhash("confirmed")).blockhash,
        timestamp,
      };
    }

    return this._cachedBlockhashInfo.blockhash;
  }
  static async sendTransaction(transaction, opts) {
    const { connection, wallet } = opts
    const existingsetComputeUnitPriceIx = transaction.instructions.find(
      (ix) =>
        ix.programId.equals(ComputeBudgetProgram.programId) &&
        ix.data.at(0) === 3 // setComputeUnitPrice
    );
    if (!existingsetComputeUnitPriceIx) {
      const priorityFeeInfo = await this._getPriorityFee(connection);
      const priorityFeeSetting = "average" | "median" | "min" | "max";
      const priorityFee =
        typeof priorityFeeSetting === "number"
          ? priorityFeeSetting
          : priorityFeeInfo[priorityFeeSetting];
      if (priorityFee) {
        const setComputeUnitPriceIx =
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: priorityFee,
          });
        transaction.instructions = [setComputeUnitPriceIx, ...transaction.instructions];
      }
    }
    transaction.recentBlockhash = await this._getLatestBlockhash(
      connection,
      opts?.forceFetchLatestBlockhash
    );
    transaction.feePayer = wallet.publicKey;

    // Add keypair signers
    if (opts?.keypairSigners?.length) transaction.partialSign(...opts.keypairSigners);

    // Add wallet signers
    if (opts?.walletSigners) {
      for (const walletSigner of opts.walletSigners) {
        transaction = await walletSigner.signTransaction(transaction);
      }
    }
    const signedTransaction = await wallet.signTransaction(transaction);
    const signature = await connection.sendRawTransaction(signedTransaction.serialize());
    await connection.confirmTransaction(signature);
    return signature;
  }
  static async send(
    tx,
    opts
  ) {
    const wallet = opts?.wallet ?? PgWallet.current;
    if (!wallet) throw new Error("Wallet not connected");
    const connection = opts?.connection;
    // Set priority fees if the transaction doesn't already have it
    const existingsetComputeUnitPriceIx = tx.instructions.find(
      (ix) =>
        ix.programId.equals(ComputeBudgetProgram.programId) &&
        ix.data.at(0) === 3 // setComputeUnitPrice
    );
    if (!existingsetComputeUnitPriceIx) {
      const priorityFeeInfo = await this._getPriorityFee(connection);
      const priorityFeeSetting = "median";
      const priorityFee =
        typeof priorityFeeSetting === "number"
          ? priorityFeeSetting
          : priorityFeeInfo[priorityFeeSetting];
      if (priorityFee) {
        const setComputeUnitPriceIx =
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: priorityFee,
          });
        tx.instructions = [setComputeUnitPriceIx, ...tx.instructions];
      }
    }


    tx.recentBlockhash = await this._getLatestBlockhash(
      connection,
      opts.forceFetchLatestBlockhash
    );
    tx.feePayer = wallet.publicKey;

    // Add keypair signers
    if (opts?.keypairSigners?.length) tx.partialSign(...opts.keypairSigners);

    // Add wallet signers
    if (opts?.walletSigners) {
      for (const walletSigner of opts.walletSigners) {
        tx = await walletSigner.signTransaction(tx);
      }
    }
    tx = await wallet.signTransaction(tx);
    let txHash;
    try {
      txHash = await connection.sendRawTransaction(tx.serialize(), {
        skipPreflight: true,
      });
    } catch (e) {
      if (
        e.message.includes("This transaction has already been processed") ||
        e.message.includes("Blockhash not found")
      ) {
        // Reset signatures
        tx.signatures = [];
        return await this.send(tx, {
          ...opts,
          forceFetchLatestBlockhash: true,
        });
      }

      throw e;
    }

    return txHash;
  }
  static async confirm(
    txHash,
    connection,
    opts
  ) {
    const result = await connection.confirmTransaction(
      txHash,
      opts?.commitment
    );
    if (result?.value.err) return { err: result.value.err };
  }
}

export default PgTx