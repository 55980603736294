import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { clusterApiUrl } from "@solana/web3.js";
import SolanaLogo from 'assets/icons/solana-logo.svg';

export const supportedChains = [
  {
    id: 0,
    name: "Solana",
    network: 'Localhost',
    rpc_url: "http://127.0.0.1:8899",
    image: SolanaLogo,
    symbol: 'SOL',
    blockExplorerUrl: "https://explorer.solana.com/?cluster=localhost"
  },
  {
    id: 1,
    name: "Solana",
    network: WalletAdapterNetwork.Testnet,
    rpc_url: clusterApiUrl(WalletAdapterNetwork.Testnet),
    image: SolanaLogo,
    symbol: 'SOL',
    blockExplorerUrl: "https://explorer.solana.com/?cluster=testnet"
  },
  {
    id: 2,
    name: "Solana",
    network: WalletAdapterNetwork.Devnet,
    rpc_url: clusterApiUrl(WalletAdapterNetwork.Devnet),
    image: SolanaLogo,
    symbol: 'SOL',
    blockExplorerUrl: "https://explorer.solana.com/?cluster=devnet"
  },
  {
    id: 3,
    name: "Solana",
    network: WalletAdapterNetwork.Mainnet,
    rpc_url: clusterApiUrl(WalletAdapterNetwork.Mainnet),
    image: SolanaLogo,
    symbol: 'SOL',
    blockExplorerUrl: "https://explorer.solana.com/"
  },
];

export default supportedChains;
