import { useCallback, useRef, useState } from 'react';
import Editor from '@monaco-editor/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateContract } from 'store/services/contracts';
import { setContract } from 'store/reducers/contract';

function CodeEditor({ height }) {
    const [editor, setEditor] = useState();
    const monacoRef = useRef(null);

    const { selectedContract, contractState } = useSelector((state) => state?.contract)
    const dispatch = useDispatch();

    const isDeployed = () => contractState?.status === 'deployed' || false;

    const saveContract = useCallback(
        _.debounce((value) => {

            // Stop call for deployed contracts
            if (isDeployed())
                return null;

            let _clonedContract = _.cloneDeep(contractState);

            if (selectedContract?._id === contractState?._id) {
                _clonedContract.sourceCode = value;
            }
            else {
                _clonedContract?.externalFiles?.forEach(c => {
                    if (c?._id === selectedContract?._id) {
                        c.sourceCode = value;
                    }
                })
            }
            updateContract(selectedContract?._id, { sourceCode: value })

            dispatch(setContract(_clonedContract));
        }, 300), [selectedContract?._id]);

    const handleEditorChange = (value) => {
        value = value?.trim();
        if (!value)
            return null;
        saveContract(value);
    };

    function handleEditorWillMount(monaco) {
        // here is the monaco instance
        // do something before editor is mounted
        const disposables = monaco.languages.getLanguages()
        console.log("disposables", disposables)
    }


    function handleEditorDidMount(editor, monaco) {
        // here is another way to get monaco instance
        // you can also store it in `useRef` for further usage
        monacoRef.current = monaco;
    }

    return <Editor
        id="code-editor"
        loading={''}
        height={height}
        width={'calc(100vw - 438px)'}
        theme="vs-dark"
        defaultLanguage='rust'
        className="overflow-hidden"
        beforeMount={handleEditorWillMount}
        onMount={handleEditorDidMount}
        options={{ glyphMargin: true, readOnly: isDeployed(), domReadOnly: isDeployed() }}
        value={selectedContract?.sourceCode}
        onChange={handleEditorChange}
    />;
}

export default CodeEditor;